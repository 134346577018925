import { combineReducers, configureStore, Reducer, ReducersMapObject } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';

/** List of reducers loaded at startup */
const staticReducers = {};

const createReducer = (asyncReducers?: ReducersMapObject) => {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers,
  });
};

export interface AsyncReducer {
  key: string;
  reducer: Reducer;
}

/*
AsyncStore is an extension of standard store interface of redux-toolkit.
We have a map of async reducers (reducers that will be injected dynamically during application runtime)and a couple of method to inject/eject them.
*/
interface AsyncStore extends ToolkitStore {
  asyncReducers?: ReducersMapObject;
  injectReducers?: (asyncReducer: AsyncReducer[]) => void;
  ejectReducers?: (asyncReducer: AsyncReducer[]) => void;
}

const store: AsyncStore = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
});

/** List of reducers loaded asyncronally */
store.asyncReducers = {};

/** Add a reducer into store */
store.injectReducers = (asyncReducers: AsyncReducer[]) => {
  asyncReducers.forEach((reducer) => {
    store.asyncReducers![reducer.key] = reducer.reducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  });
};

/** Remove a reducer from store using his key */
store.ejectReducers = (asyncReducers: AsyncReducer[]) => {
  const newReducers: ReducersMapObject = {};
  const keysToBeRemoved = asyncReducers.map((r) => r.key);
  for (const key in store.asyncReducers) {
    if (!keysToBeRemoved.includes(key)) {
      newReducers[key] = store.asyncReducers[key];
    }
  }
  store.replaceReducer(createReducer(newReducers));
};

export type RootState = ReturnType<typeof store.getState>;

export const rootStateSelector = (state: RootState) => state;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export default store;
