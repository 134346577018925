import React from 'react';
import { toast, Toast as Toaster, ToastPosition } from 'react-hot-toast';
import FontIcon from '@shared/FontIcon/FontIcon';
import cn from 'classnames';
import { FontIcons } from '@constants/fontIcons';
import { t } from 'i18next';
import { commonNS } from '@core/i18n/common.namespace';

export const getGenericErrorToastObj: () => {
  message: PreconfiguredToast['message'];
  description: PreconfiguredToast['description'];
} = () => ({
  message: t(commonNS('errors.genericErrorTitle')),
  description: t(commonNS('errors.genericErrorDescription')),
});

export enum ToastVariant {
  error = 'error',
  success = 'success',
  warning = 'warning',
  info = 'info',
}

const iconByType = (variant: ToastVariant) => {
  switch (variant) {
    case ToastVariant.success:
      return FontIcons['confirm-check'];
    case ToastVariant.error:
      /* TODO USE PROPER ICON WHEN READY */
      return FontIcons['warning-soft'];
    case ToastVariant.warning:
      /* TODO USE PROPER ICON WHEN READY */
      return FontIcons['warning-soft'];
    case ToastVariant.info:
      return FontIcons['info'];
  }
};
const getIconSizeByVariant = {
  [ToastVariant.success]: 'text-xs',
  [ToastVariant.error]: 'text-2xl',
  [ToastVariant.warning]: 'text-2xl',
  [ToastVariant.info]: 'text-2xl',
};
const getBgColorByVariant = {
  [ToastVariant.success]: 'bg-toastBg-success',
  [ToastVariant.error]: 'bg-toastBg-error',
  [ToastVariant.warning]: 'bg-toastBg-warning',
  [ToastVariant.info]: 'bg-toastBg-info',
};
const getIconBgColorByVariant = {
  [ToastVariant.success]: 'bg-toastActive-success',
  [ToastVariant.error]: 'bg-toastActive-error',
  [ToastVariant.warning]: 'bg-toastActive-warning',
  [ToastVariant.info]: 'bg-toastActive-info',
};
const getBorderColorByVariant = {
  [ToastVariant.success]: 'border-toastActive-success',
  [ToastVariant.error]: 'border-toastActive-error',
  [ToastVariant.warning]: 'border-toastActive-warning',
  [ToastVariant.info]: 'border-toastActive-info',
};
const getTextColorByVariant = {
  [ToastVariant.success]: 'text-toastActive-success',
  [ToastVariant.error]: 'text-toastActive-error',
  [ToastVariant.warning]: 'text-toastActive-warning',
  [ToastVariant.info]: 'text-toastActive-info',
};

export interface ToastProps {
  closable?: boolean;
  description?: string;
  message?: string;
  t: Toaster;
  variant: ToastVariant;
  icon?: FontIcons;
}

export interface PreconfiguredToast {
  message: string;
  description?: string | null;
  options?: Omit<ToastProps, 'message' | 't' | 'variant'>;
  id?: string;
  position?: ToastPosition;
}

export const defaultToastPosition: ToastPosition = 'top-right';

export const errorToast: (config: PreconfiguredToast) => void = ({
  message,
  description,
  options,
  id,
  position = defaultToastPosition,
}) => {
  toast.custom(
    (t) => (
      <ToastCustom t={t} variant={ToastVariant.error} message={message} description={description || ''} {...options} />
    ),
    {
      id,
      position,
    },
  );
};

export const warningToast: (config: PreconfiguredToast) => void = ({
  message,
  description,
  options,
  id,
  position = defaultToastPosition,
}) => {
  toast.custom(
    (t) => (
      <ToastCustom
        t={t}
        variant={ToastVariant.warning}
        message={message}
        description={description || ''}
        {...options}
      />
    ),
    {
      id,
      position,
    },
  );
};

export const infoToast: (config: PreconfiguredToast) => void = ({
  message,
  description,
  options,
  id,
  position = defaultToastPosition,
}) => {
  toast.custom(
    (t) => (
      <ToastCustom t={t} variant={ToastVariant.info} message={message} description={description || ''} {...options} />
    ),
    {
      id,
      position,
    },
  );
};

export const successToast: (config: PreconfiguredToast) => void = ({
  message,
  description,
  options,
  id,
  position = defaultToastPosition,
}) => {
  toast.custom(
    (t) => (
      <ToastCustom
        t={t}
        variant={ToastVariant.success}
        message={message}
        description={description || ''}
        {...options}
      />
    ),
    {
      id,
      position,
    },
  );
};

const ToastCustom: React.FC<ToastProps> = ({
  message,
  description,
  t,
  variant = ToastVariant.info,
  closable = true,
  icon = null,
}) => {
  const bgClasses = cn(
    [
      'relative',
      'top-16',
      'right-5',
      'pr-4',
      'flex',
      'max-w-2xl',
      'w-full',
      'border-2',
      'rounded-xxl',
      'rounded-lg',
      'pointer-events-auto',
      'shadow-lg',
    ],
    t.visible ? 'animate-enter' : 'animate-leaves',
    `${getBgColorByVariant[variant]}`,
    `${getBorderColorByVariant[variant]}`,
  );

  return (
    <div className={bgClasses}>
      <div className="flex-1 w-0 p-4">
        <div className="flex items-center">
          <div
            className={`flex justify-center items-center flex-shrink-0 w-12 h-12 rounded-full ${getIconBgColorByVariant[variant]}`}
          >
            <FontIcon name={icon || iconByType(variant)} size={getIconSizeByVariant[variant]} color={`text-white`} />
          </div>
          <div className="ml-3 flex-1">
            <p className="text-base font-semibold text-tundrBlack-100">{message}</p>
            {description && <p className="mt-1 leading-5 text-sm text-tundrBlack-70 font-normal">{description}</p>}
          </div>
        </div>
      </div>
      {closable && (
        <FontIcon
          onClick={() => toast.dismiss()}
          name={'close'}
          size={'text-2xl'}
          className={`absolute top-2 right-2`}
          color={getTextColorByVariant[variant]}
        />
      )}
    </div>
  );
};
export default ToastCustom;
