import { lazy, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './App.css';

import { Toaster } from 'react-hot-toast';
import { PreferenceProvider } from '@context/PreferenceContext';
import { UserConsumer, UserProvider } from '@context/UserContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';

import store from '@core/store/store.config';
import { Provider } from 'react-redux';
import Loader from '@components/Loader';
import { defaultToastPosition } from '@shared/Toast/Toast';

const AppLoader = (
  <div className="flex items-center justify-center w-full h-full">
    <Loader />
  </div>
);

// Containers
const NewLayout = lazy(() => import('@containers/V2/NewLayout'));

// Pages
const Login = lazy(() => import('./views/pages/Login/Login'));
const Page404 = lazy(() => import('./views/pages/page404/Page404'));
const Page500 = lazy(() => import('./views/pages/page500/Page500'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000,
    },
  },
});

function App() {
  return (
    <RecoilRoot>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <UserProvider value={{}}>
            <PreferenceProvider value={{}}>
              <Suspense fallback={AppLoader}>
                {/*<ReactQueryDevtools initialIsOpen={false}>*/}
                <Toaster position={defaultToastPosition} />
                <BrowserRouter>
                  <Switch>
                    <Route exact path="/login" name="Login/Register Page" render={(props) => <Login {...props} />} />
                    <Route exact path="/auth" name="Login/Register Page" render={(props) => <Login {...props} />} />
                    <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                    <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
                    <Route
                      path="/"
                      name="Home"
                      render={() => (
                        <UserConsumer>
                          {(props) => {
                            if (!props.isLoggedIn) {
                              return (
                                <Redirect
                                  to={{
                                    pathname: '/auth',
                                  }}
                                />
                              );
                            } else {
                              return <NewLayout {...props} />;
                            }
                          }}
                        </UserConsumer>
                      )}
                    />
                  </Switch>
                </BrowserRouter>
              </Suspense>
            </PreferenceProvider>
          </UserProvider>
        </QueryClientProvider>
      </Provider>
    </RecoilRoot>
  );
}

export default App;
