import axios from 'axios';
import { Component, createContext } from 'react';

import API from './API';
import { errorToast, getGenericErrorToastObj } from '@shared/Toast/Toast';

const UserContext = createContext({});

export const UserConsumer = UserContext.Consumer;

class UserProvider extends Component {
  state = {
    isLoggedIn: false,
    isGift: true,
    breadCrumb: undefined,
    user: {},
    preferences: {},
    axios: null,
    swanOAuth: {},
  };

  constructor() {
    super();
    if (localStorage.hasOwnProperty('context')) {
      const state = JSON.parse(localStorage.getItem('context'));
      state.axios = this.generateAxios(state.user);
      this.state = state;
    } else {
      this.state.axios = this.generateAxios(null);
    }
  }

  setBreadCrumb = (breadCrumb) => {
    this.setState({ breadCrumb });
  };

  logOutIfFail = (promise) => {
    promise.catch((error) => {
      if (error.message.includes('401')) {
        console.warn('Unauthorized');
        errorToast(getGenericErrorToastObj());
        this.signOut();
      }
    });
  };

  loadFromCache = (path) => {
    const cache = localStorage.getItem(path);

    if (cache) {
      return JSON.parse(cache).map((item) => ({
        value: parseInt(item.id),
        label: item.name,
      }));
    } else {
      return [];
    }
  };

  sendDAReq = async (method, url, data, object = true, logOutOnFailure = true, withHeader = true) => {
    try {
      const d = await axios({
        url: new URL(url, API.baseURL_DA).toString(),
        method: method,
        format: 'json',
        headers: withHeader ? { Authorization: `Bearer ${this.state.user?.token}` } : {},
        data,
      });
      return object ? d.data : d;
    } catch (e) {
      if (logOutOnFailure && e?.message?.includes('401')) this.signOut();
      return e;
    }
  };

  sendReq = async (method, url, data, object = true, logOutOnFailure = true, withHeader = true) => {
    try {
      const d = await axios({
        url: new URL(url, API.baseURL).toString(),
        method: method,
        format: 'json',
        headers: withHeader ? { Authorization: `Bearer ${this.state.user?.token}` } : {},
        data,
      });
      return object ? d.data : d;
    } catch (e) {
      if (logOutOnFailure && e?.message?.includes('401')) this.signOut();
      throw new Error(e);
    }
  };

  signOut = async () => {
    this.setUser(null);
    localStorage.removeItem('@preferences');
    localStorage.removeItem('context');
  };

  userLogic = (callback) => {
    if (!this.state.user) {
      localStorage.removeItem('context');
    } else {
      localStorage.setItem(
        'context',
        JSON.stringify({
          ...this.state,
          isGift: this.state.isGift,
          axios: null,
        }),
      );
      callback();
    }
  };

  generateAxios = (user) => {
    const headers = user ? { Authorization: `Bearer ${user.token}` } : undefined;
    return axios.create({
      baseURL: import.meta.env.VITE_BASE_API_URL,
      timeout: 60000,
      headers,
    });
  };

  getOAuthToken = (redirectUrl) => {
    let { swanOAuth } = this.state;
    if (!('refreshToken' in swanOAuth) && 'code' in swanOAuth) {
      this.state.axios
        .post('/oauth/swanToken/', {
          code: swanOAuth.code,
          redirectUrl,
        })
        .then(({ data }) => {
          swanOAuth = { ...swanOAuth, ...data };
          this.setState({ swanOAuth });
          localStorage.setItem(
            'context',
            JSON.stringify({
              ...this.state,
              axios: null,
              swanOAuth,
            }),
          );
        });
    } else if ('refreshToken' in swanOAuth) {
      this.state.axios
        .post('/oauth/swanToken/', {
          refreshToken: swanOAuth.refreshToken,
        })
        .then(({ data }) => {
          swanOAuth = { ...swanOAuth, ...data };
          this.setState({ swanOAuth });
          localStorage.setItem(
            'context',
            JSON.stringify({
              ...this.state,
              axios: null,
              swanOAuth,
            }),
          );
        });
    } else {
      // nop
    }
  };

  setUser = (user, callback = () => {}) => {
    const state = { user, isLoggedIn: !!user };
    if (user) state.axios = this.generateAxios(user);
    this.setState(
      (prevState) => state,
      () => {
        this.userLogic(callback);
      },
    );
  };

  setLoggedIn = (isLoggedIn) => {
    this.setState((prevState) => ({ isLoggedIn }));
  };

  setSwanOAuth = (swanOAuth) => {
    this.setState({ swanOAuth });
  };

  checkHasToken = (callback = () => {}) => {
    this.sendReq('GET', 'oauth/hastoken?redirectUrl=' + window.location.href, undefined, false)
      .then((res) => {
        if (res !== null && res.data !== null) {
          if (res?.data) window.location.href = res.data;
        }
        callback();
      })
      .catch(callback);
  };

  render() {
    const { children } = this.props;
    const { user, isLoggedIn, isGift, axios, swanOAuth, breadCrumb } = this.state;
    const {
      setUser,
      setLoggedIn,
      setSwanOAuth,
      getOAuthToken,
      sendReq,
      sendDAReq,
      logOutIfFail,
      loadFromCache,
      setBreadCrumb,
      checkHasToken,
      signOut,
    } = this;

    return (
      <UserContext.Provider
        value={{
          user,
          isLoggedIn,
          isGift,
          breadCrumb,
          setBreadCrumb,
          setUser,
          setLoggedIn,
          axios,
          setSwanOAuth,
          swanOAuth,
          getOAuthToken,
          sendReq,
          sendDAReq,
          logOutIfFail,
          loadFromCache,
          checkHasToken,
          signOut,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}

export default UserContext;
export { UserProvider };
