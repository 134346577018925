/// <reference types="vite-plugin-svgr/client" />
import './index.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom/client';
import 'regenerator-runtime';
import App from './App';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: !isNaN(Number(import.meta.env.VITE_SENTRY_LOG_LEVEL))
      ? Number(import.meta.env.VITE_SENTRY_LOG_LEVEL)
      : undefined,
  });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />,
  </React.StrictMode>,
);
