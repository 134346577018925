import i18n, { InitOptions, TFunction } from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const defaultNS = 'common';
export const defaultLanguage = 'it';

function initLocalization(options: InitOptions): Promise<TFunction> {
  return i18n
    .use(I18NextHttpBackend)
    .use(initReactI18next)
    .init({
      interpolation: {
        escapeValue: false,
      },
      returnNull: false,
      returnObjects: false,
      ...options,
      backend: {
        ...options.backend,
      },
    });
}

initLocalization({
  lng: defaultLanguage,
  fallbackLng: defaultLanguage,
  defaultNS,
  ns: defaultNS,
  debug: process.env.NODE_ENV === 'development',
}).catch((err) => console.error(err));
