import React from 'react';
import cn from 'classnames';
import { Tooltip } from 'react-tooltip';

interface FontIconProps {
  name: string;
  id?: number | string;
  color?: string;
  title?: string;
  size?: string;
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties | undefined;
  testId?: string;
  disabled?: boolean;
  squareBorders?: boolean;
  roundBorders?: boolean;
  borderColor?: string;
  tooltip?: string;
}

const FontIcon: React.FC<FontIconProps> = React.memo(
  ({
    id,
    testId,
    name,
    title,
    color = 'text-tundrBlack100',
    size = 'text-lg',
    className = '',
    disabled,
    onClick,
    style,
    squareBorders,
    roundBorders,
    borderColor = 'border-grigio25Border',
    tooltip,
    ...rest
  }) => {
    const withBordersClasses =
      squareBorders || roundBorders
        ? `flex items-center justify-center p-4 border ${borderColor} w-10 h-10 ${
            squareBorders ? 'rounded-lg' : 'rounded-full'
          }`
        : '';
    const disabledClasses = disabled ? 'cursor-not-allowed opacity-50' : '';
    const clickableClasses =
      onClick !== undefined
        ? 'transition linear duration-20 cursor-pointer transform-gpu hover:scale-105 active:scale-100'
        : 'cursor-auto';
    const fontIconClasses = cn(className, size, color, `icon-${name}`, disabledClasses, clickableClasses);

    const iconProps = {
      title,
      'data-testid': testId,
      onClick,
      role: onClick ? 'button' : 'icon',
      style,
      ...rest,
    };
    return (
      <>
        {withBordersClasses ? (
          <div data-tooltip-id={`chip-tooltip-${id}`} className={withBordersClasses} {...iconProps}>
            <div className={fontIconClasses} />
          </div>
        ) : (
          <div data-tooltip-id={`chip-tooltip-${id}`} className={fontIconClasses} {...iconProps} />
        )}
        {tooltip && (
          <Tooltip float place="top" id={`chip-tooltip-${id}`}>
            <span>{tooltip}</span>
          </Tooltip>
        )}
      </>
    );
  },
);

FontIcon.displayName = 'FontIcon';

export default FontIcon;
