export enum FontIcons {
  'card-refresh' = 'card-refresh',
  'card-new' = 'card-new',
  'card-conflict' = 'card-conflict',
  'headquarters' = 'headquarters',
  'faq' = 'faq',
  'groups' = 'groups',
  'eye_hidden' = 'eye_hidden',
  'eye_visible' = 'eye_visible',
  'confirm-check' = 'confirm-check',
  'mobilita' = 'mobilita',
  'multiuso' = 'multiuso',
  'confirm-chec' = 'confirm-chec',
  'add-user' = 'add-user',
  'add' = 'add',
  'arrow-small' = 'arrow-small',
  'arrow-close' = 'arrow-close',
  'Benefit' = 'Benefit',
  'block-user' = 'block-user',
  'branch' = 'branch',
  'calendar' = 'calendar',
  'card---add-new' = 'card---add-new',
  'card---block' = 'card---block',
  'card---recharge' = 'card---recharge',
  'card---refresh' = 'card---refresh',
  'card-active' = 'card-active',
  'card-allert' = 'card-allert',
  'card' = 'card',
  'Care' = 'Care',
  'Change' = 'Change',
  'close' = 'close',
  'clothing' = 'clothing',
  'cloud-upload' = 'cloud-upload',
  'co2' = 'co2',
  'colosseum' = 'colosseum',
  'copyright' = 'copyright',
  'dashboard' = 'dashboard',
  'delete-file' = 'delete-file',
  'discount' = 'discount',
  'document-transfer' = 'document-transfer',
  'double-arrow' = 'double-arrow',
  'download-arrow' = 'download-arrow',
  'edit' = 'edit',
  'email' = 'email',
  'euro' = 'euro',
  'filter' = 'filter',
  'fuel' = 'fuel',
  'group' = 'group',
  'holidays' = 'holidays',
  'home' = 'home',
  'info' = 'info',
  'kebab-menu' = 'kebab-menu',
  'learning' = 'learning',
  'list' = 'list',
  'Loading' = 'Loading',
  'location' = 'location',
  'lock---point' = 'lock---point',
  'lock---x' = 'lock---x',
  'lock-plan' = 'lock-plan',
  'lock' = 'lock',
  'logout' = 'logout',
  'makeup' = 'makeup',
  'map' = 'map',
  'Party' = 'Party',
  'phone-1' = 'phone-1',
  'phone-and-card' = 'phone-and-card',
  'phone' = 'phone',
  'photo-off' = 'photo-off',
  'photo' = 'photo',
  'plan' = 'plan',
  'Refund-split' = 'Refund-split',
  'Refund' = 'Refund',
  'refunds' = 'refunds',
  'remove-user' = 'remove-user',
  'search' = 'search',
  'settings' = 'settings',
  'shop' = 'shop',
  'sofa' = 'sofa',
  'sort-by' = 'sort-by',
  'sport-1' = 'sport-1',
  'sport' = 'sport',
  'store-move' = 'store-move',
  'streaming' = 'streaming',
  'Success' = 'Success',
  'Support' = 'Support',
  'tech' = 'tech',
  'time' = 'time',
  'transfer-bank' = 'transfer-bank',
  'transfer-voucher' = 'transfer-voucher',
  'travel-1' = 'travel-1',
  'travel' = 'travel',
  'Tundr-EAT' = 'Tundr-EAT',
  'Tundr-Gift' = 'Tundr-Gift',
  'Tundr-Move' = 'Tundr-Move',
  'unsuccess' = 'unsuccess',
  'user-hr' = 'user-hr',
  'user' = 'user',
  'voucher' = 'voucher',
  'Wallet-refund' = 'Wallet-refund',
  'wallet' = 'wallet',
  'warning-hard' = 'warning-hard',
  'warning-soft' = 'warning-soft',
  'icon-warning' = 'icon-warning',
}

export const FontIconsList = Object.values(FontIcons);
