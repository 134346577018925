import React, { createContext, useContext } from 'react';
import UserContext from './UserContext';

const PreferenceContext = createContext();

export const PreferenceConsumer = PreferenceContext.Consumer;

const PreferenceProvider = (props) => {
  const userContext = useContext(UserContext);
  const [preferences, setPreferences] = React.useState(JSON.parse(localStorage.getItem('@preferences')) || []);

  const cleanPreferences = () => {
    localStorage.removeItem('@preferences');
    setPreferences(undefined);
  };

  const fetchPreferences = (cb = undefined) => {
    userContext?.sendReq('GET', 'preferences', undefined, false).then((res) => {
      if (res.status === 200 || res.status === 204) {
        setPreferences(res?.data);
        localStorage.setItem('@preferences', JSON.stringify(res?.data));
      }
      if (cb) cb();
    });
  };

  const updatePreferences = (newPreferences) => {
    const payload = {
      jsonString: JSON.stringify({ ...preferences, ...newPreferences }),
    };

    userContext?.sendReq('PUT', 'preferences', payload, false).then((res) => {
      fetchPreferences();
    });
  };

  React.useEffect(() => {
    if (preferences === undefined) fetchPreferences();
  }, []);

  React.useEffect(() => {}, [preferences]);

  return (
    <PreferenceContext.Provider
      value={{
        preferences,
        setPreferences,
        updatePreferences,
        fetchPreferences,
      }}
    >
      {props.children}
    </PreferenceContext.Provider>
  );
};

export default PreferenceContext;
export { PreferenceProvider };
