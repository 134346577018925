import { RefreshIcon } from '@heroicons/react/solid';

const Loader = () => {
  return (
    <div className="m-auto w-20 items-center pt-3 text-center ">
      <RefreshIcon className="animate-spin text-gray-300 w-20 h-20"></RefreshIcon>
    </div>
  );
};

export default Loader;
